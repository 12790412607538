import * as React from "react"

import OurServices from "../components/ourServices/ourServices"
import Seo from "../components/seo"
import "../assets/css/style.css"
import "../assets/css/layout.css"
import "../assets/css/services-style.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { organizationSchema } from "../const/schemas"
import { languages } from "../const/languages"

const ServicesPage = () => <OurServices lang={languages.EN} />

export const Head = () => {
  return (
    <>
      <Seo
        title="AR-Labs.io | Professional 3D product digitisation! "
        description="Are you searching for the best 3D graphic designers? Check out our experienced and talented team and bring your products to augmented reality with us."
        canonicalURL="https://ar-labs.io/services"
      />
      <script type="application/ld+json">{organizationSchema}</script>
    </>
  )
}

export default ServicesPage
